import { useEffect, useMemo } from 'react'
import { Account } from 'src/auth/Account'

import EventTracker from '../EventTracker'
import { PageName, PageProperties } from '../pages'
import { PageTrackerOptions } from './types'

export function useCreateTracker<PN extends PageName>(
  pageName: PN,
  pageProperties: PageProperties<PN>,
  pageTrackerOptions?: PageTrackerOptions,
  account?: Account
) {
  const tracker = useMemo(
    () => new EventTracker<PN>(pageName, pageProperties, pageTrackerOptions),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (account?.userId) {
      tracker.setUser(account.userId, account.userEmail)
    }
  }, [account?.userEmail, account?.userId, tracker])

  useEffect(() => {
    tracker.updatePageProperties(pageProperties)
  }, [tracker, pageProperties])

  useEffect(() => {
    tracker.updateShouldPublishToHost(!!pageTrackerOptions?.shouldPublishToHost)
  }, [pageTrackerOptions?.shouldPublishToHost, tracker])

  return tracker
}
