import { isLocalEnv, isStagingEnv } from 'src/helpers'

export let ANALYTICS_URL = process.env.ANALYTICS_URL as string

if (!ANALYTICS_URL) {
  const prodApiDomain = 't.arcade.show'
  const stagingApiDomain = 'staging.arcade.software'
  const localApiDomain = 'localhost:3000'
  const isLocal = isLocalEnv()
  const isStaging = isStagingEnv()

  const protocol = isLocal ? 'http' : 'https'

  const host = isLocal
    ? localApiDomain
    : isStaging
    ? stagingApiDomain
    : prodApiDomain

  ANALYTICS_URL = `${protocol}://${host}/api/ingest`
}
