import { AuthAction } from 'next-firebase-auth'
import withAuth from 'src/auth/withAuth'
import AuthPage from 'src/components/Auth/AuthPage'
import { AuthProvider } from 'src/components/Auth/FirebaseAuth'

const Auth = () => {
  const providers: AuthProvider[] = [{ type: 'google' }, { type: 'microsoft' }]

  return <AuthPage providers={providers}></AuthPage>
}

export default withAuth({
  whenAuthed: AuthAction.RENDER,
  whenUnauthedBeforeInit: AuthAction.RETURN_NULL,
  whenUnauthedAfterInit: AuthAction.RENDER,
})(Auth)
