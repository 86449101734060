import pick from 'lodash/pick'

import { PageEvents, PageName, PageProperties } from './pages'

type PublishableEvent<PN extends PageName, EN extends keyof PageEvents<PN>> = {
  pageProperties: (keyof PageProperties<PN>)[]
  eventProperties: (keyof PageEvents<PN>[EN])[]
}

export const publishableEvents: {
  [PN in PageName]?: {
    [EN in keyof PageEvents<PN>]?: PublishableEvent<PN, EN>
  }
} = {
  Viewer: {
    'Flow Rendered': {
      pageProperties: ['flowId', 'flowName'],
      eventProperties: [],
    },
    'Hotspot Clicked': {
      pageProperties: ['flowId', 'flowName'],
      eventProperties: ['stepId', 'hotspotId', 'hotspotLabel'],
    },
    'CTA Clicked': {
      pageProperties: ['flowId', 'flowName'],
      eventProperties: ['stepId', 'ctaLabel'],
    },
    'Video Ended': {
      pageProperties: ['flowId', 'flowName'],
      eventProperties: ['stepId'],
    },
    'Flow Restarted': {
      pageProperties: ['flowId', 'flowName'],
      eventProperties: [],
    },
    'Progress Bar Nav Clicked': {
      pageProperties: ['flowId', 'flowName'],
      eventProperties: ['fromStepId', 'toStepId'],
    },
    'Step Reached': {
      pageProperties: ['flowId', 'flowName'],
      eventProperties: ['fromStepId', 'isFinalStep', 'isInitialStep', 'stepId'],
    },
    'Overlay Clicked': {
      pageProperties: ['flowId', 'flowName'],
      eventProperties: [
        'buttonLabel',
        'isCta',
        'linkType',
        'linkUrl',
        'title',
        'subtitle',
        'stepId',
        'buttonId',
      ],
    },
  },
}

type Event = {
  name: string
  event: object
}

const evts: Event[] = []
function flushEvents(evts: Event[], intervalId?: NodeJS.Timer) {
  if (window?.__arcadeTracker?.track) {
    while (evts.length > 0) {
      const evt = evts.shift()
      evt && window.__arcadeTracker.track(evt?.name, evt?.event)
    }
    if (intervalId) {
      clearInterval(intervalId)
    }
  }
}
function track(name: Event['name'], event: Event['event']) {
  if (window?.__arcadeTracker?.track) {
    if (evts.length) {
      evts.push({ name, event })
      flushEvents(evts)
    } else {
      window.__arcadeTracker.track(name, event)
    }
  } else {
    evts.push({ name, event })
    const intervalId: NodeJS.Timer = setInterval(
      () => flushEvents(evts, intervalId),
      1000
    )
  }
}

export function propagateEvent<
  PN extends PageName,
  EN extends keyof PageEvents<PN>
>(
  pageName: PN,
  eventName: EN,
  pageProperties: PageProperties<PN>,
  eventProperties: PageEvents<PN>[EN]
) {
  const pageEvents = publishableEvents[pageName]

  if (!pageEvents || !(eventName in (pageEvents as object))) return

  const eventDefinition = pageEvents[eventName] as PublishableEvent<PN, EN>
  if (typeof eventDefinition === 'undefined') return

  const event = {
    eventName,
    eventSource: 'Arcade',
    eventHostname: window.location.hostname,
    eventPathname: window.location.pathname,
    eventTimestamp: new Date().toISOString(),
    ...pick(pageProperties, eventDefinition.pageProperties),
    ...pick(eventProperties, eventDefinition.eventProperties),
  }

  window?.parent?.postMessage(event, '*')
  track(event.eventName as string, event)
}
